import React from 'react';
import settings from '../../../settings';

import TeamMember from '../team/teammember';

// Instructor's Images
const AdamSeamanPopup = `${settings.IMAGES_BASE_URL}/v2/images/mini/classes/desktop/instructors/Adam_Seaman_popUp_MINI.jpg`;
const AdamSeaman = `${settings.IMAGES_BASE_URL}/v2/images/mini/classes/desktop/instructors/Adam_Seaman_sml_MINI.jpg`;
const ChristopherHill = `${settings.IMAGES_BASE_URL}/v2/images/mini/classes/desktop/instructors/Christopher_Hill_sml_MINI.jpg`
const ChristopherHillPopup = `${settings.IMAGES_BASE_URL}/v2/images/mini/classes/desktop/instructors/Christopher_Hill_popUp_MINI.jpg`;
const RobStoutPopup = `${settings.IMAGES_BASE_URL}/v2/images/mini/classes/desktop/instructors/Rob_Stout_popUp_MINI.jpg`;
const RobStout = `${settings.IMAGES_BASE_URL}/v2/images/mini/classes/desktop/instructors/Rob_Stout_sml_MINI.jpg`;
const DaveMcMillian = `${settings.IMAGES_BASE_URL}/v2/images/mini/classes/desktop/instructors/Dave_McMillian_sml_MINI.jpg`
const DaveMcMillianPopup = `${settings.IMAGES_BASE_URL}/v2/images/mini/classes/desktop/instructors/Dave_McMillan_popUp_MINI.jpg`;


const data = [
  {
    id: 1,
    name: 'Adam Seaman',
    image: `${AdamSeaman}`,
    popupimage: `${AdamSeamanPopup}`,
    introduction:
      'This BMW Internationally Certified Instructor has competed in Drift races and holds a Formula Drift Pro license. He also does stunt driving for NBC’s DRIVE, and loves coaching skidpad and Lead/Follow on the larger racetracks. His key to driving skill improvement? “Vision. Disciplining your vision is the most difficult thing to master – but also the most important.'
  },
  {
    id: 2,
    name: 'Christopher Hill',
    image: `${ChristopherHill}`,
    popupimage: `${ChristopherHillPopup}`,
    introduction:
      'Christopher is the performance driver’s dream instructor. A veteran of over 18 years teaching racing and high-performance driving, he has earned multiple instructor certifications, including BMW in Germany. On TV, he’s appeared on American Hot Rod and was the runner-up on Season 3 of GT Academy. Now Christopher calls the BMW Performance Driving School home, encouraging students to think and push the limit, understanding how to get the most out of their car and leave with greater confidence in their ability.'
  },
  {
    id: 3,
    name: 'Rob Stout',
    image: `${RobStout}`,
    popupimage: `${RobStoutPopup}`,
    introduction:
      'A red-blooded competitor, Rob Stout has won victories at the Long Beach Grand Prix, Toronto Grand Prix and is a Pirelli World Challenge TC Champion. In addition to stunt driving, he has been a MINI instructor since 2014. His favorite course is handling: "Pushing drivers to get around a course as fast as possible while doing it on the proper line is what it\'s all about."'
  },
  {
    id: 4,
    name: 'Dave McMillian',
    image: `${DaveMcMillian}`,
    popupimage: `${DaveMcMillianPopup}`,
    introduction:
      'Dave McMillan began mixing it up in Motocross at the tender age of 13. By 23, he graduated to four-wheel competition, taking an SCCA Championship. After working as a test driver for the Department of Transportation in Crash Avoidance, his training talents were recognized in 2015 when he was asked to join the MINI Driving Experience team.'
  },
  
];

class Team extends React.Component {
  state = {
    show: false,
    teammembers: [],
    activeTeamMember: '',
    modalImage: '',
    modalTitle: '',
    modalDescription: '',
    selectedIndex: 0
  };

  componentDidMount() {
    this.setState({teammembers: data})
  }

  handleModalOpen = (selectedIndex) => {
    this.setState({
      show: true,
      activeTeamMember: selectedIndex,
      modalImage: this.state.teammembers[selectedIndex].popupimage,
      modalTitle: this.state.teammembers[selectedIndex].name,
      modalDescription: this.state.teammembers[selectedIndex].introduction
    })
  }

  handleModalClose = () => {
    this.setState({
      show: false,
      activeTeamMember: ''
    })
  }

  handleNextTeamMember = () => {

    let arr = this.state.teammembers.length;
    let selectedIndex = this.state.activeTeamMember + 1;
    selectedIndex = selectedIndex  % arr;

    this.setState ({
      show: true,
      activeTeamMember: selectedIndex,
      modalImage: this.state.teammembers[selectedIndex].popupimage,
      modalTitle: this.state.teammembers[selectedIndex].name,
      modalDescription: this.state.teammembers[selectedIndex].introduction
    })

  }

  handlePreviousTeamMember = () => {

    let arr = this.state.teammembers.length;
    let selectedIndex = this.state.activeTeamMember;

    if (selectedIndex === 0) {
      selectedIndex = arr - 1;
    } else {
      selectedIndex = selectedIndex - 1;
    }

    this.setState ({
      show: true,
      activeTeamMember: selectedIndex,
      modalImage: this.state.teammembers[selectedIndex].popupimage,
      modalTitle: this.state.teammembers[selectedIndex].name,
      modalDescription: this.state.teammembers[selectedIndex].introduction
    })

  }

  render() {
    const { teammembers, modalDescription, modalImage, modalTitle } = this.state;
    return (
      <section className="team__wrapper">
        <div className="container">
          <h2 className="team__header-text"> <span>Meet Your Instructors</span></h2>
          <div className="team">
            {teammembers.map((teammember, selectedIndex) => {
              return (
                <React.Fragment key={teammember.id}>
                  <TeamMember
                    name={teammember.name}
                    index={selectedIndex}
                    title={teammember.title}
                    image={teammember.image}
                    introduction={teammember.introduction}
                    onModalOpen={this.handleModalOpen}
                    onModalClose={this.handleModalClose}
                    onNext={this.handleNextTeamMember}
                    onPrev={this.handlePreviousTeamMember}
                    modalImage={modalImage}
                    modalTitle={modalTitle}
                    modalDescription={modalDescription}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default Team;
